<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h4 class="font-weight-normal">
              Listagem de Permissões
            </h4>
          </b-col>
        </b-row>
      </div>
      <div class="table-responsive">
        <table class="table table-flush-spacing">
          <b-tbody>
            <b-tr
              v-for="permission in permissions"
              :key="permission.id"
            >
              <b-td class="text-nowrap fw-bolder px-1">
                {{ permission.description }}
              </b-td>
              <b-td
                v-for="permission in permission.permissions"
                :key="permission.id"
                class="px-1"
              >
                {{ permission.permission }}
              </b-td>
            </b-tr>
          </b-tbody>
        </table>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BCardText,
  BTr,
  BTd,
  BTbody,
} from 'bootstrap-vue'
import axios from '@axios'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BTable,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    BTr,
    BTd,
    BTbody,
  },
  data() {
    return {
      permissions: [],
    }
  },
  created() {
    this.fetchPermissions()
  },
  methods: {
    fetchPermissions() {
      axios.get('/permission/gruoped-by-module').then(res => {
        console.log(res.data)
        this.permissions.push(...res.data)
      })
    },
  },
}
</script>
<style scoped>
.fw-bolder {
  font-weight: 600 !important;
}
</style>
